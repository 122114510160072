<template>
  <v-card elevation="0" height="955" class="pa-3">
    <v-card-text class="pa-3 pt-0 mt-0 sticky-title" >
      <v-row class="ma-0 pt-0" dense>
        <v-col cols="10" class="text-left pb-0">
          <v-row class="pb-0" dense>
            <v-col class="pb-0 text-left pl-0 mb-2" cols="12">
              <span class="font-size24 font-weight-bold black--text">{{ detailedUser.userData.firstName + ' ' + detailedUser.userData.lastName }}</span>
            </v-col>
            <v-col cols="12">
              <div style="display: flex; align-items: center;">
                <v-icon size="20px" color="primary">mdi-email</v-icon>
                <span class="ml-2 font-size13 color-primary"> {{ detailedUser.userData.email }}</span>
              </div>
              <div style="display: flex; align-items: center;">
                <v-icon size="20px" color="primary" >mdi-phone</v-icon>
                <span class="ml-2 color-primary font-size13">{{phone(detailedUser.userData.phone)}}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="2" class="ma-0 pa-0 mt-1 d-flex">
          <div>
            <v-icon small class="mr-1" style="margin-top:-2px;" :style="detailedUser.userData.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
            <span  :style="{ fontWeight: detailedUser.userData.status === 1 ? 'bold' : 'bold', color: detailedUser.userData.status === 1 ? '#09CB09' : '' }" class="mr-2">
                {{ detailedUser.userData.status === 1 ? $t('active-caps') : $t('inactive-caps') }}
              </span>
          </div>
        </v-col>
      </v-row>
      <!-- Grid -->
      <v-row class="pa-0 ma-0 mx-0 mt-3">
        <v-col cols="4" class="data-table-outline border-radius-left5 d-flex flex-column-reverse pa-0">
          <span>{{ $t("age") }}</span>
          <span class="font-weight-bold black--text">{{ calculateAge(detailedUser.userData.birthdate) }}</span>
        </v-col>
        <v-col cols="4" class="data-table-outline flex-column d-flex pa-0">
          <span class="font-weight-bold black--text">{{userSettings.Units === "METRIC" ?
              detailedUser.userData.patientMetadata.weightInKg : toLbs
              (detailedUser.userData.patientMetadata.weightInKg)}}
              {{userSettings.Units === "METRIC" ? 'kg' : 'lbs'}}
           </span>
          <span>{{ $t("weight") }}</span>
        </v-col>
        <v-col cols="4" class="data-table-outline border-radius-right5 d-flex flex-column-reverse pa-0">
          <span>{{$t('height')}}</span>
          <span class="font-weight-bold black--text">{{ userSettings.Units === 'IMPERIAL' ?
              formatHeightInFeet(detailedUser.userData.patientMetadata.heightInCm)
              : detailedUser.userData.patientMetadata.heightInCm + ' cm' }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Diagnoses Lists -->
    <v-card class="elevation-0" :height="pageHeight(160)" style="overflow:scroll; overflow-x: hidden">
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size-small-bold-text ml-4">{{ $t("knownDiagnosis") }}</span>
          </v-col>
        </v-card-title>
        <v-card-text v-html="getLatestDiagnosis" style="overflow-y: auto; max-height: 15vh;" class="pa-2"></v-card-text>
      </v-card>
      <!-- Treatment  -->
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size-small-bold-text ml-4">{{$t("treatment-summary")}}</span>
          </v-col>
        </v-card-title>
        <v-card-text v-html="getTreatmentSummary" style="overflow-y: auto; max-height: 15vh;" class="py-0"></v-card-text>
      </v-card>
      <!-- Allergies  -->
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size-small-bold-text ml-4">{{ $t("allergies") }}</span>
          </v-col>
        </v-card-title>
        <v-card-text v-html="getAllergies" style="overflow-y: auto; max-height: 15vh;" class="py-0"></v-card-text>
      </v-card>
      <!-- Medical team-->
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size-small-bold-text ml-4">{{ $t("medicalTeam") }}</span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <h6 @click="triggerMedicalTeam" class="float-right mr-2 color-primary pointer font-size14">{{$t('view-all')}}</h6>
          </v-col>
        </v-card-title>
        <v-card-text class="text-left" style="overflow-y: auto; max-height: 10vh;">
          <h6 class="my-2 font-size14">{{$t('primary-doctor')}}: {{ detailedUser.userData.medicalTeamsData[0].primaryDoctorFullName }}</h6>
          <v-col v-if="showMedicalTeam" class="pa-0">
          <span
              v-for="member in detailedUser.userData.medicalTeamsData[0].membersList"
              :key="member.id"
              class="d-block"
          >
            <span>{{member.firstName}} {{member.lastName}}</span>
          </span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-card>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { fromTo } from '@/utils/unit-converter.js';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
export default {
	props: [],
	data () {
		return {
      showMedicalTeam: false,
		};
	},
	computed: {
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
    }),
    ...mapGetters({ userSettings: 'authentication/getUserSettings', pageHeight: 'pageHeight' }),
    getTreatmentSummary () {
      const diagnoses = this.detailedUser.userData.patientDiagnosisList.map(diagnosis => {
        return `<p class="mb-1"><b>${diagnosis.diagnosisCode}</b> ${diagnosis.diagnoseDescription}</p>`;
      });
      return `<div class="text-left px-0">${diagnoses.join('')}</div>`;
    },
    getAllergies () {
      const metadataInfo = this.detailedUser.userData.patientMetadata.diagnose;
      const formattedMetadataInfo = `<p class="mb-1">${metadataInfo}</p>`;
      return `<div class="text-left">${formattedMetadataInfo}</div>`;
    },
    getLatestDiagnosis () {
      // Extract the diagnosis list from patientDiagnosisList
      const diagnosisList = this.detailedUser.userData.patientDiagnosisList;

      // Check if diagnosisList is null or empty
      if (!diagnosisList || diagnosisList.length === 0) {
        return '<p class="px-2 text--secondary" style="font-size: 15px">' + this.$t('no-diagnoses-found') + '</p>';
      }

      // Create an array to store the formatted diagnosis entries
      const formattedDiagnoses = [];

      // Iterate through the diagnosisList
      for (const diagnosis of diagnosisList) {
        // Assuming diagnosis has a property named 'description', you can adjust accordingly
        const formattedDiagnosisEntry = `<p class="mb-1"><b>${diagnosis.diagnosisCode}</b> ${diagnosis.diagnoseDescription}</p>`;
        formattedDiagnoses.push(formattedDiagnosisEntry);
      }

      // Join the formatted entries into a single string
      const formattedDiagnosisString = formattedDiagnoses.join('');

      // Wrap the entire string in a div tag to ensure each diagnosis appears on a new line
      return `<div class="text-left px-2">${formattedDiagnosisString}</div>`;
    },
	},
	methods: {
    formatHeightInFeet () {
      if (this.userSettings.Units === 'IMPERIAL') {
        const totalInches = Math.round(this.detailedUser.userData.patientMetadata.heightInCm / 2.54); // 1 inch = 2.54 cm
        const feet = Math.floor(totalInches / 12);
        const inches = totalInches % 12;

        return `${feet}' ${inches}"`;
      } else {
        return this.detailedUser.userData.patientMetadata.heightInCm + ' cm';
      }
    },
    triggerMedicalTeam () {
      this.showMedicalTeam = !this.showMedicalTeam;
    },
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				return new AsYouType().input(val);
			}
		},
		toFeet (value) {
			return fromTo(value, 'cm', 'ft-us').toFixed(2);
		},
		toLbs (value) {
			return fromTo(value, 'kg', 'lb').toFixed(0);
		},
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString?.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
	},
};
</script>
